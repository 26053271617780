import request from '@/utils/request'
// import payRequest from '@/utils/payRequest'
import bbsRequest from '@/utils/bbsrequest'

// 修改用户信息
export function updateRegistInfo(params) {
  return request({
    url: '/personal/Myinfo/regisInfo',
    method: 'get',
    params: params
  })
}

// // 获取省市地区
// export function getDistrict(params) {
//   return payRequest({
//     url: '/pay/address/getDistrict',
//     method: 'get',
//     params: params
//   })
// }

// 推荐可关注的人
export function followList(params) {
  return request({
    url: '/personal/Task/followList',
    method: 'get',
    params: params
  })
}
// 添加关注
export function addFollow(params) {
  return bbsRequest({
    url: '/user/follow/follow',
    method: 'get',
    params: params
  })
}

// 获取专业
export function getMajorList(params) {
  return request({
    url: '/personal/Myinfo/getMajorList',
    method: 'get',
    params: params
  })
}
// 完成关注任务领奖接口
export function followGetScore(params) {
  return request({
    url: '/personal/Task/followGetScore',
    method: 'get',
    params: params
  })
}
// 签到
export function signReq(params) {
  return request({
    url: '/api/user/sign',
    method: 'get',
    params: params
  })
}